.paginationWrapper {
    margin: 3rem auto 0 auto;
    width: 90%;
}

.statusLabel {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
}

.noSurveyText {
    margin-top: 2rem !important;
}

.noResultContainer {
    display: flex;
}

.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
    width: 100%;
}
