.detailsTable {
    display: grid;
    grid-template-rows: 1.25rem 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 0.5rem;
    margin: 1.5rem 0;
}

.detailsTable.oneColumn {
    grid-template-columns: 44rem;
}

.detailsTable.twoColumn {
    grid-template-columns: repeat(2, 22rem);
}