.nameContainer {
    margin-top: .25rem;
    margin-bottom: 2rem;
}

.iconContainer {
    display: flex;
    justify-content: center;
}

.messageContainer {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.successMessage {
    text-align: center;
}