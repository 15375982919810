.editIcon {
    width: 32px;
    height: 32px;
    padding: 6px;
    border-radius: 50%;
    background-color: #eeeeee;
    opacity: 0.5;
}

.editButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: .5rem .5rem 0rem 1rem;
    padding: 0;

    border: none;
    width: auto;
    overflow: visible;
    background: transparent;
    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;
    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;
    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;

}

