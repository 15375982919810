.mainContainer > div {
    padding: 0;
    /*display: flex;*/
    /*flex-direction: column;*/
}

.modalChildrenContainer {
    width: 100%;
    height: 100%;
    /*if available use fill-available, since it uses the space better. If not, height 100% should be used*/
    height: -webkit-fill-available;
    position: absolute;
    top: -40px;
    display: flex;
    flex-direction: column;
}

.conflictCounterAndActionsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.conflictCounterContainer {
    align-self: flex-start;
    flex-grow: 7;
}

.conflictActionsContainer {
    align-self: center;
    flex-grow: 2;
}

.conflictActionsContainer > span {
    margin-right: 3em;
}

.conflictActionsContainer > span:last-child {
    margin-right: 0;
}

.headerTitle {
    padding-left: 167px;
    padding-top: 49px;
    padding-bottom: 20px;
}

.actionButtonContainer {
    width: 100%;
    padding-top: 20px;
    align-self: flex-end;
}

.actionButtonGroup {
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 154px;
}

.actionButtonGroup > button {
    margin-right: 1em;
    min-width: 6em;
}

.conflictCounterLabel {
    padding-left: 167px;
    padding-top: 49px;
    padding-bottom: 0.5em;
}

.conflictCounterMessageLabel {
    padding-left: 167px;
    padding-bottom: 20px;
    font-size: 20px !important;
    color: #495A63;
}

.bodyContainer {
    padding-left: 154px;
    width: 94%;
    padding-top: 49px;
    padding-bottom: 62px;
    min-height: 220px;
    height: calc(100% - 90px);
    overflow: auto;
}

.conflictInfoTableCell {
    width: 65em;
}

.conflictResolutionButtonTableCell {
    width: 280px;
}

.tableRowSurveyLabel {
    font-size: 20px !important;
}

.tableRowSurveyConflictListLabel {
    font-size: 18px !important;
    color: #9E9E9E;
}

.clickableLabel {
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    text-transform: uppercase;
}

.surveyNameLink {
    display: block;
    width: fit-content;
    text-decoration: none;
}

.surveyNameLink:visited {
    text-decoration: none;
}

.goToSurveyLink {
    text-decoration: underline;
    color: #CA0029;
}

.goToSurveyLink:visited {
    text-decoration: underline;
}

.infoCircle {
    background-color: #D9D9D9;
    border-radius: 25px;
    text-transform: capitalize;
    width: 31px;
    height: 31px;
    text-align: center;
    padding-top: 5px;
    display: inline-block;
    margin-left: 1em;
}

.conflictResolvedMessageContainer {
    padding-left: 167px;
    width: 90%;
}
.conflictResolvedMessage {
    background-color: #00A811;
    color: white;
    display: block;
    padding: 1rem;
}

.successPageContainer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.successLogo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -100%);
}

.allConflictsResolvedLabel {
    font-size: 20px !important;
    color: #495A63;
    padding-top: 4em;
}

.conflictResolutionErrorLabel {
    color: #CA0029;
}

