.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
    margin-right: 2.5rem;
}

.actions {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    gap: 40px;
}

.closeIcon {
    cursor: pointer;
}
