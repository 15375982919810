.contentContainer {
    margin-top: 1rem;
    margin-bottom: .5rem;
}

.titleContainer {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

.input {
    min-width: 380px !important;
}