.menuItemContainer {
    display: flex;
    align-items: center;
}

.iconContainer {
    margin-left: .5rem;
    display: flex;
    justify-content: center;
    align-self: center;
}