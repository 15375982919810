.ruleContainer {
    display: flex;
    border: 1px solid #e0e0e0;
    border-radius: 80px;
    width: 100%;
    min-height: 56px;
    margin-bottom: 12px;
    position: relative;
}

.wrapRule {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    align-items: center;
    width: 100%;
    margin-top: 0.75rem;
    margin-bottom: 1rem;
    margin-left: 2.5rem;
}

.editButton {
    position: relative;
    align-self: center;
    margin-right: 1.5rem;
    margin-left: 0.25rem;
}

.wrapButton {
    margin-left: auto;
}

.editRule {
    color: #de5000;
    text-transform: uppercase;
    cursor: pointer;
}

.ruleChip {
    background-color: #f5f5f5;
    color: #000000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 33px;
    padding: 3px 13px;
    margin-left: 6px;
    margin-right: 6px;
}
