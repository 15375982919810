.titleWrapper {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.titleContainer {
    display: flex;
    min-width: 0;
    overflow: hidden;
    align-items: flex-start;
}

.actionButtonsBesideTitle {
    display: flex;
    gap: 0.5rem;
    align-self: flex-start;
}

.status {
    display: block !important;
    text-transform: uppercase;
}

.surveyName {
    min-width: 0;
    overflow-wrap: break-word;
}
