.container {
    margin: 5rem 0 0 10rem;
    max-width: 40rem;
}

.p0 {
    padding: 0 !important;
}

.m1 {
    margin-bottom: 1rem !important;
}

.m2 {
    margin-bottom: 2rem !important;
}
