html {
    overflow-y: scroll;
}

.contentContainer {
    padding-top: 4.5rem;
    padding-bottom: 7rem;
}

.modalTitleContainer {
    margin-bottom: 2rem;
}

.modalInputContainer {
    margin-bottom: 2.5rem;
}

.modalButtonContainer {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
}

.checkbox-list-icon {
    border-radius: 50%;
    width: 60px !important;
    height: 60px !important;
    flex-shrink: 0;
}

/* Hide builtin export translation button */
#svc-translation-export {
    display: none;
}

.creator-container {
    height: calc(100vh - 150px);
}