.editFlightRulesContainer {
    display: flex;
    justify-content: space-between;
    border: 1px solid #e0e0e0;
    border-radius: 28px;
    padding: 1.5rem;
}

.multiSelectInput {
    flex-grow: 1;
}

.saveButtonContainer {
    margin-left: 1.5rem;
    margin-right: 0.5rem;
    margin-top: 1rem;
}

.editRule, .processingRule {
    text-transform: uppercase;
}

.editRule {
    color: #de5000;
    cursor: pointer;
}

.processingRule {
    color: #7f7f7f;
    cursor: progress;
}
