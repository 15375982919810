.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 2rem;
}

.headerButtons {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
