.headerWrapper {
    margin: 3rem 0;
}

.blockWrapper {
    margin-bottom: 5rem;
}

.timeTriggerOnboardingTable {
    display: grid;
    grid-template-columns: repeat(1, 44rem);
    grid-template-rows: 1.25rem 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 0.5rem;
    margin: 1.5rem 0;
    margin-top: 3.125rem;
}

.backgroundImageUploaderWrapper {
    margin-top: 3rem;
}
