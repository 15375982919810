.noRuleContainer {
    display: flex;
    background-color: #f5f5f5;
    border-radius: 59px;
    width: 100%;
    height: 56px;
    margin-bottom: 1rem;
    justify-content: center;
}

.wrapCenterNoRule {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 24px;
    margin-right: 37px;
}

.wrapText {
    display: flex;
    flex-grow: 1;
    justify-content: center;
}

.wrapButton {
    margin-left: auto;
}

.editRule {
    color: #de5000;
    text-transform: uppercase;
    cursor: pointer;
}