.descriptionContainer {
    margin-top: .75rem;
    min-height: 19rem;
}

.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 19rem;
    width: 100%;
}